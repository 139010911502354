import React from "react";
import "./Timeline.css";

const Timeline = ({ type }) => {
  const stages = [
    {
      service: "architecture",
      data: [
        {
          week: "1",
          title: "SITE VISIT & CONSULTATION",
          desc: "Upon receiving a 30% deposit, we will arrange a site visit that includes up to 2 hours of consultation, surveying, and photography.",
        },
        {
          week: "2",
          title: "DRAFTING & REVIEW",
          desc: "Once the job is queued to our drafters, we will review the plans and send to you within 5 - 10 business days.",
        },
        {
          week: "3",
          title: "CLIENT FOLLOW-UP & REVISIONS",
          desc: "We will follow up with you to complete any revisions and alterations. The number of revisions included will be specified in our Terms and Conditions.",
        },
        {
          week: "4",
          title: "PLANNING SUBMISSION & BUILD QUOTE",
          desc: "We handle planning permission applications if instructed & liaise with your local council. Once approved, we provide a free construction quote tailored to your budget.",
        },
      ],
    },
    {
      service: "construction",
      data: [
        {
          week: "STAGE 1",
          title: "SITE VISIT & INITIAL CONSULTATION",
          desc: "Upon receipt of your drawings, we arrange a <b>FREE</b> site visit of up to 1 hour, followed up by an initial quote within 5 working days.",
        },
        {
          week: "STAGE 2",
          title: "QUOTE REVIEW PERIOD",
          desc: "We understand that you may require time to analyse & tailor your build quote. We will follow up with you once you are ready to proceed.",
        },
        {
          week: "STAGE 3",
          title: "SECURE A START DATE",
          desc: "Once you are satisfied with the quote, we issue T&C's and you can secure a preferred build start date with a 5% deposit.",
        },
        {
          week: "STAGE 4",
          title: "STARTING ONSITE",
          desc: "We ensure that Building Control Submission forms are completed on your behalf and oversee the project through to its completion.",
        },
      ],
    },
  ];

  return (
    <div
      className={`timeline ${
        type === "architecture" ? "back-drop" : "cons-back-drop"
      }`}
    >
      <div className="timeline-container">
        <ul className="text-box">
          {type === "architecture"
            ? stages[0].data.map((item, index) => (
                <li className="architecture-timeline font-thin" key={index}>
                  <p className="conthrax text-[#FFCE89]">
                    STAGE
                    <span className="font-bold conthrax pl-2">{item.week}</span>
                  </p>
                  <h1>{item.title}</h1>
                  <p
                    className="flex text-justify"
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                  />
                  <span className="circle"></span>
                </li>
              ))
            : stages[1].data.map((item, index) => (
                <li className="construction-timeline" key={index}>
                  <p className="conthrax text-[#FFCE89]">{item.week}</p>
                  <h1>{item.title}</h1>
                  <p
                    className="text-justify leading-7 font-normal"
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                  />
                  <span className="circle"></span>
                </li>
              ))}
        </ul>
      </div>
    </div>
  );
};

export default Timeline;
