import React from "react";
import "./Intro2.css";
import CountUp from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Intro2() {
  const navigate = useNavigate();

  const { ref: countRef, inView: countInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const count = [
    { title: "Years of Combined Experience", start: 0, end: 30, symbol: "+" },
    { title: "Completed Projects", start: 800, end: 1000, symbol: "+" },
    { title: "All RIBA Project Stages", start: 0, end: 7, symbol: "" },
    { title: "Success Rate", start: 0, end: 95, symbol: "%" },
  ];

  const handleQuoteClick = () => {
    navigate("/contact");
  };

  return (
    <div>
      <div className="container">
        <div className="overflow-hidden">
          <h1 className="intro-title conthrax">
            DESIGN & BUILD
            <br />
            SPECIALISTS
          </h1>
        </div>
        <div className="intro-buttons">
          <motion.div
            className="btn2"
            onClick={handleQuoteClick}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <p>FREE Quote</p>
            <FontAwesomeIcon icon={faArrowRight} className="btn2-icon" />
          </motion.div>
        </div>
      </div>
      <div className="intro-count" ref={countRef}>
        {count.map((data, index) => (
          <motion.div
            key={index}
            className="count-text"
            initial={{ opacity: 0, y: 50 }}
            animate={countInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <span>
              <CountUp start={data.start} end={data.end} duration={5} />
              <span>{data.symbol}</span>
            </span>
            <p>{data.title}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Intro2;
