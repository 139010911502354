import React from "react";
import "./Section2.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Section2() {
  const { ref: sectionRef, inView: sectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div className="section2" ref={sectionRef}>
      <motion.div
        className="section2-left"
        initial={{ opacity: 0, x: -50 }}
        animate={sectionInView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 0.9 }}
      >
        <div>
          <img
            src="/assets/images/section2-front-img-final.png"
            alt="section-2"
          />
        </div>
      </motion.div>
      <motion.div
        className="section2-right"
        initial={{ opacity: 0, x: 0 }}
        animate={sectionInView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 0.5 }}
      >
        <h1>WHY CHOOSE PARAGON</h1>
        <p>
          We understand the pain points in a project from start to finish. Our
          bespoke Architecture and Construction services ensure commitment to
          quality, safety, and professional excellence at every stage. Our team
          ensures to keep you informed throughout your project, guiding you with
          the relevant expertise to achieve innovative designs and reliable
          construction solutions that adhere to the highest industry standards.
        </p>
        <p>
          We are chartered by{" "}
          <span className="italic">
            The Royal Institute of British Architects, The Architects
            Registration Board
          </span>{" "}
          & accredited by{" "}
          <span className="italic">
            The Contractors Health & Safety Assessment Scheme
          </span>
          .
        </p>
        <div className="section2-bottom">
          <a
            target="_blank"
            href="https://www.architecture.com/"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/images/riba.png"
              alt="riba"
              className="section-img-2 mt-6 cursor-pointer"
            />
          </a>
          <a
            target="_blank"
            href="https://www.arb.org.uk/"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/images/arb.png"
              alt="arb"
              className="section-img-2 mt-6 cursor-pointer"
            />
          </a>
          <a
            target="_blank"
            href="https://www.chas.co.uk/"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/images/Chas.png"
              alt="chas"
              className="section-img-3 mt-1 cursor-pointer"
            />
          </a>
          <a
            target="_blank"
            href="http://www.localsurveyorsdirect.co.uk/"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/images/localsurveyorsdirect-logo.png"
              alt="riba"
              className="section-img-4 -mt-2 cursor-pointer"
            />
          </a>
        </div>
      </motion.div>
    </div>
  );
}

export default Section2;
