import React from "react";
import "./ThirdPartyServices.css";

const ThirdPartyServices = () => {
  const archdata = [
    {
      title: "Party Wall Surveyor",
      desc: "We recommend hiring a qualified and experienced professional, ideally with at least 5 years of experience with the Party Wall Act 1996. They should ideally be a member of the Pyramus and Thisbe Club, recommended by the RICS, or the Faculty of Party Wall Surveyors.",
    },
    {
      title: "Ecology Surveyor",
      desc: "Protecting declining plant and animal species is crucial for any development. You may be required to consider potential impacts on local wildlife as part of your planning application; an Ecological Survey might be necessary to identify any special requirements.",
    },
    {
      title: "Flood Risk Assessment",
      desc: "This assessment will be required to gauge how at risk a building is of flooding if your project is within a flood risk zone. You can check this on",
      link: true,
      url: "",
    },
    {
      title: "CCTV Drainage Surveys",
      desc: "This involves inspecting your drains for any damage or blockages. The term CCTV refers to the camera used by these professionals to evaluate the condition of your pipes.",
    },
    {
      title: "Planning Consultant",
      desc: "Instructing this professional may be useful for evaluating the viability of your development. They can offer planning statements & tailored advice. On the occasion that your application may be refused, they can prepare an appeal case for you.",
    },
    {
      title: "Acoustic Consultant",
      desc: "During your planning application, a noise impact assessment may be required if your project is deemed to significantly affect your project or be affected by the surrounding area or neighbouring properties.",
    },
  ];

  const consdata = [
    {
      title: "Structural Engineer",
      desc: "Structural engineers are essential for projects affecting your home's structure, like rear extensions, dormer loft conversions, or chimney removals. After securing planning approval and before construction starts, the engineer will calculate the proposed structure's weight and specify key elements like steel beams.",
    },
    {
      title: "Building Control",
      desc: "This authority reviews the construction details of your project during the design stage to ensure compliance with British construction standards. They also conduct regular on-site inspections and issue a certificate upon the completion of the build.",
    },
    {
      title: "Local Water Authorities",
      desc: "This authority reviews plans for drainage, sewage, and water supply systems in order to provide approvals for connections to public water infrastructure and building over existing drains.",
    },
  ];
  return (
    <div className="tps-container">
      <div className="tps-header">
        <h1>THIRD PARTY SERVICES</h1>
      </div>
      <div className="tps-arch-container">
        <h1>ARCHITECTURE</h1>
        <div className="tps-arch-data">
          {archdata.map((item) => (
            <div className="tps-info">
              <h3>{item.title}</h3>
              <p>{item.desc}</p>
              {item.link ? (
                <a
                  href="https://flood-map-for-planning.service.gov.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tps-url"
                >
                  https://flood-map-for-planning.service.gov.uk/
                </a>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="tps-arch-container">
        <h1>CONSTRUCTION</h1>
        <div className="tps-cons-data margin-bottom">
          {consdata.map((item) => (
            <div className="tps-info">
              <h3>{item.title}</h3>
              <p>{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThirdPartyServices;
