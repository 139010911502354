import "./Footer.css";
import { CgMail } from "react-icons/cg";
import { BiSolidPhone } from "react-icons/bi";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import PrivacyPolicy from "./PrivacyPolicy.pdf";
import { CiLocationOn } from "react-icons/ci";
import { Link } from "react-router-dom";

function Footer() {
  const socials = [
    {
      icon: FaInstagram,
      id: "instagram",
      url: "https://www.instagram.com/paragon.a.c?igsh=dGUzZ3Z6eGt3ZnRm",
    },
    {
      icon: FaFacebook,
      id: "facebook",
      url: "https://www.facebook.com/profile.php?id=61561859695503",
    },
    {
      icon: FaLinkedinIn,
      id: "linkedin",
      url: "https://www.linkedin.com/paragon-architecture-construction-uk",
    },
  ];

  const details = [
    { title: "Home", id: "home", url: "/" },
    { title: "Services", id: "services", url: "/services" },
    { title: "Projects", id: "projects", url: "/projects" },
    { title: "About", id: "about", url: "/about" },
    { title: "Contact", id: "contact", url: "/contact" },
  ];

  return (
    <div className="footer">
      <div className="footer-left">
        <div className="footer-contact">
          <span>
            <BiSolidPhone />
          </span>
          <p>024 7772 1468</p>
        </div>
        <div className="footer-contact">
          <span>
            <CgMail />
          </span>
          <p className="footer-contact-text">info@pacltd.co.uk</p>
        </div>
      </div>
      <div className="footer-center">
        <div className="footer-company">
          {details.map((item) => (
            <Link key={item.id} to={item.url} className="">
              {item.title}
            </Link>
          ))}
        </div>
        <p className="footer-license">
          © 2024 PARAGON ARCHITECTURE AND CONSTRUCTION. ALL RIGHTS RESERVED. |
          <a href={PrivacyPolicy} target="_blank" rel="noreferrer">
            PRIVACY POLICY & LEGAL NOTICE
          </a>
        </p>
      </div>
      <div className="footer-right">
        <div className="location">
          <span>
            <CiLocationOn />
          </span>
          <p>Based in Midlands</p>
        </div>
        <div className="flex justify-end footer-socials-container">
          <div className="footer-socials">
            {socials.map((item) => (
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                key={item.id}
                className="socials"
              >
                <item.icon size={25} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
