import React from "react";
import { motion } from "framer-motion";
import { CgClose } from "react-icons/cg";
import { useNavigate, useLocation } from "react-router-dom";
import "./SideNav.css";

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "100%" },
};

function SideNav({ show, toggleSidebar }) {
  const navigate = useNavigate();
  const location = useLocation();

  const data = [
    { title: "Home", path: "/" },
    { title: "Services", path: "/services" },
    { title: "Projects", path: "/projects" },
    { title: "About", path: "/about" },
    { title: "Contact", path: "/contact" },
  ];

  const handleNavigation = (path) => {
    navigate(path);
    toggleSidebar(); // Close the sidebar after navigation
  };

  return (
    <div>
      <motion.nav
        initial={false}
        animate={show ? "open" : "closed"}
        variants={variants}
        transition={{ type: "tween", duration: 0.5 }}
        className="side-nav"
      >
        <motion.button
          onClick={toggleSidebar}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="text-white"
        >
          <CgClose size={24} />
        </motion.button>
        <div className="nav-titles">
          {data.map((item, index) => (
            <div
              key={index}
              className={`flex items-center cursor-pointer ${
                location.pathname === item.path ? "active" : ""
              }`}
              onClick={() => handleNavigation(item.path)}
            >
              <span className="header-anime">{item.title}</span>
            </div>
          ))}
        </div>
      </motion.nav>
    </div>
  );
}

export default SideNav;
