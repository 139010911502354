import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ContactBtn.css";
import React from "react";
import { Link } from "react-router-dom";

const ContactBtn = ({ justify, text = "" }) => {
  return (
    <div
      className={`contact-button-container ${
        justify === "center" ? "justify-center items-center" : ""
      }`}
    >
      <div className={`contact-button  ${text === "FREE Quote" ? "mb-7" : ""}`}>
        <FontAwesomeIcon icon={faPhone} />
        <Link to="/contact">
          {text === "FREE Quote" ? "FREE Quote" : "Contact Us"}
        </Link>
      </div>
    </div>
  );
};

export default ContactBtn;
