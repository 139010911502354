import React from "react";
import "./OurServices.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

function OurServices() {
  const { ref: servicesRef, inView: servicesInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const data = [
    {
      title: "ARCHITECTURE",
      class: "card-1",
      url: "/services",
    },
    {
      title: "CONSTRUCTION",
      class: "card-2",
      url: "/services",
    },
    {
      title: "DESIGN & BUILD",
      class: "card-3",
      url: "/services",
    },
  ];

  return (
    <div className="main-container">
      <motion.div
        className="our-services"
        ref={servicesRef}
        initial={{ opacity: 0, scale: 0.9 }}
        animate={servicesInView ? { opacity: 1, scale: 1 } : {}}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          className="main-house"
          initial={{ opacity: 0 }}
          animate={servicesInView ? { opacity: 1 } : {}}
          transition={{ duration: 2 }}
        >
          <div className="overlay"></div>
          <div className="overlay-text">
            <div>
              <span className="header-a text-b">
                Bringing your vision to Life
              </span>
              <br />

              <p className="text-b small-text">
                Specialising in Residential & Commercial Architecture and
                Construction, our team is committed to delivering high quality
                designs & finishes that exceed expectations. We ensure to be
                present at every stage to ensure a stress-free process.
              </p>
            </div>
          </div>
          <div className="patio-mainframe">
            <motion.img
              src="/assets/images/our-services-cover-2-final.jpg"
              alt="patio"
              className="patio"
              initial={{ x: "-20%", opacity: 0 }}
              animate={servicesInView ? { x: 0, opacity: 1 } : {}}
              transition={{ duration: 1, delay: 0.5 }}
            />
          </div>
        </motion.div>
      </motion.div>

      <div className="services-container">
        <div className="services-title">
          <h1>OUR SERVICES</h1>
        </div>
        <div className="services mt-8">
          {data.map((item, index) => (
            <Link
              to={item.url}
              key={index}
              className={`service-cards ${item.class}`}
            >
              <div className="title-div">
                <p className="pt-1">{item.title}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurServices;
